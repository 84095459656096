html {
  font-size: 0.875rem
}

body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background-color: #888;
}

.ag-theme-material.core-theme .ag-row-selected {
  background-color: #EDE7F6;
}

/* .ag-theme-material.core-theme .ag-row-hover:not(.ag-row-selected) {
  background-color: none;
} */

.ag-theme-material.core-theme .ag-floating-bottom-container .ag-row-hover {
  background-color: transparent;
}

.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: #5E35B1 !important;
}

.ag-theme-material.core-theme .ag-floating-bottom .ag-center-cols-viewport {
  overflow-y: hidden !important;
}

.ag-theme-material.core-theme .ag-center-cols-viewport {
  overflow: hidden !important;
}
